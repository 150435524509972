import { FC, useRef, useState } from 'react';
import { Option } from '../../Option';
import { SelectListMenu } from '../SelectListMenu';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import Button, { ButtonSize, ButtonType } from './Button';
import { ChevronIcon, ChevronType } from '../icon/ChevronIcon';
import withSlot, { SlotDefinitions } from '../../../wrappers/withSlot';
import useSlot from '../../../hooks/useSlots';

export enum DropdownSize {
  S,
  M,
}

export enum DropdownStyle {
  DEFAULT,
  TERTIARY_FILLED,
}

type DropdownProps = {
  options: Option<string, string | number>[];
  className?: string;
  onSelect: (data: Option<string, string | number>) => void;
  name?: string;
  disabled?: boolean;
  customListRenderer?: FC<Option<string, string | number>>;
  dropdownWidth?: string;
  noMatchMessage?: string;
  fitToScreen?: boolean;
  isFixed?: boolean;
  ignoreMinWidth?: boolean;
  size?: ButtonSize;
  type?: ButtonType;
  enableChevron?: boolean;
};

const DropdownButton = withSlot<DropdownProps, SlotDefinitions<['Icon']>>((props) => {
  const {
    className,
    options,
    onSelect,
    disabled,
    customListRenderer,
    dropdownWidth = 'max-w-[250px]',
    noMatchMessage,
    isFixed,
    children,
    ignoreMinWidth,
    size,
    type = ButtonType.PRIMARY,
    enableChevron = true,
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const selfRef = useRef<HTMLDivElement>(null);

  const toggleOpen = (e: React.MouseEvent<HTMLButtonElement>): void => {
    setIsOpen(!isOpen);

    // Going to be open
    if (!isOpen) {
      const el = e.target as HTMLButtonElement;
      el.scrollTo();
    }
  };

  const onOptionClick = (option: Option<string, string | number>): void => {
    onSelect(option);
    setIsOpen(!isOpen);
  };

  const iconSlot = useSlot(props, 'Icon');

  return (
    <SelectListMenu
      placement="top-end"
      width={dropdownWidth}
      isFixed={isFixed}
      options={options}
      isOpen={isOpen}
      onClick={(option) => onOptionClick(option)}
      customListItemRenderer={customListRenderer}
      noMatchMessage={noMatchMessage}
      onBlur={() => setIsOpen(false)}
    >
      {(triggerProps) => (
        <div {...triggerProps}>
          <div className={`relative rounded-md ${className}`} {...dataAttributeProps(props)} ref={selfRef}>
            <Button type={type} size={size} onClick={toggleOpen} disabled={disabled} ignoreMinWidth={ignoreMinWidth}>
              {iconSlot() && <Button.Slot name="Icon">{iconSlot()}</Button.Slot>}
              <div className="flex items-center">
                <span className="-mb-[2px]">{children}</span>
                {enableChevron && <ChevronIcon type={isOpen ? ChevronType.UP : ChevronType.DOWN} className="ml-2 h-5 w-5" />}
              </div>
            </Button>
          </div>
        </div>
      )}
    </SelectListMenu>
  );
});

export default DropdownButton;
